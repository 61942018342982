import { create } from 'zustand';
import type { Profile } from '~/types/partner';

interface PartnerState {
  partner: Profile;
  setPartner: (partner: Profile) => void;
}

const initialState: Profile = {
  partnerId: 0,
  partnerName: '',
  partnerUserId: 0,
  partnerUserName: '',
};

export const usePartnerStore = create<PartnerState>((set) => ({
  partner: initialState,
  setPartner: (partner: Profile) => set({ partner }),
}));
